import { useAuth } from 'lib/firebase/use-auth'
import { db } from 'lib/firebase/firebase'
import { generateKeywords } from 'lib/keywordGenerator'

import { Prompt } from 'react-router'
import { Link, useHistory } from 'react-router-dom'

import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import Layout from 'components/layout'
import TinyEditor from 'components/info/tiny-editor'
import FeatureImage from 'components/info/upload-feature-image'

import Modal from 'react-modal'
import { ToolTipController, Select } from 'react-tooltip-controller'
import { toast } from 'react-toastify'

import { useDebouncedCallback } from 'use-debounce'

import { getSlug, getPostDate } from 'lib/post'
import { infoCategoryList, infoStatusList } from 'lib/info'

export default function Index() {
  useAuth()
  const history = useHistory()
  const { id } = useParams()

  const toastRef = useRef(null)
  const [data, setData] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [dataUpdated, setDataUpdated] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const doc = await db.collection('info-pages').doc(id).get()
      if (doc.exists) {
        setData({...doc.data(), id: doc.id})
        setDataLoaded(true)
      }
    }
    fetchData()
    Modal.setAppElement('body')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function toastContent(data) {
      return (
        <div className='flex items-center'>
          <button className='btn-primary mr-3' onClick={() => save(data)}>Save</button>
          <span className='leading-none block -mb-1'>
            Your post has changes!<br />
            <span className='text-xs text-gray-700'>This will auto save after a bit.</span>
          </span>
        </div>
      )
    }
    if(dataLoaded && dataUpdated) {
      onSave(data)
      const toastSettings = {
        className: () => {
          return 'bg-gray-900 rounded-xl border-2 border-gray-800 flex justify-between text-white p-3'
        },
        bodyClassName: () => {
          return 'text-sm'
        },
        position: 'bottom-left',
        closeButton: false,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined
      }
      if(!toastRef.current) {
        toastRef.current = toast(toastContent(data), toastSettings)
      } else {
        toast.update(toastRef.current, {
          render: () => toastContent(data),
          ...toastSettings
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataUpdated])

  const onChange = (value, name) => {
    setDataUpdated(true)

    if(name === 'title') {
      const slug = getSlug(value)
      setData(prevState => ({ ...prevState, [name]: value, slug: slug }))
    } else {
      setData(prevState => ({ ...prevState, [name]: value }))
    }
  }

  const onSave = useDebouncedCallback((newData) => {
    save(newData)
  }, 10000)

  const save = async (newData) => {
    onSave.cancel()
    await db.collection('info-pages').doc(newData.id).update({
      ...newData,
      id: undefined,
      keywords: generateKeywords([data.title]),
      updatedAt: new Date()
    })
    setDataUpdated(false)
    toast.dismiss(toastRef.current)
    toastRef.current = null
  }

  const onRemove = async () => {
    await db.collection('info-pages').doc(id).delete()
    history.push('/info')
  }

  return (
    <Layout>
      <Prompt
        when={dataUpdated}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <main>
        {dataLoaded &&
          <div className='p-6 py-32 fade-in'>

            <div className='flex flex-wrap items-center justify-between max-w-2xl m-auto'>
              <div className='mb-6'>
                <Link
                  to='/info'
                  className='btn-gray border-2 border-gray-900 flex items-center'>
                  <svg className='block mr-3 w-4 h-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M7 16l-4-4m0 0l4-4m-4 4h18' />
                  </svg>
                  Back to Pages
                </Link>
              </div>
              <div className='mb-4 flex flex-wrap items-center'>
                <div className='mb-2 mr-2 z-10'>
                  <ToolTipController
                    detect='click'
                    offsetY={10}
                    offsetX='right'
                    animation='fadeIn'
                    duration='.3s'
                    timing='ease'
                    properties={['opacity', 'transform']}>
                    <Select>
                      <button className='btn-gray border-2 border-gray-900 flex items-center'>
                        <span className='text-gray-700 mr-2'>Category</span>
                        <span className='truncate'>{data.category.label}</span>
                        <svg className='block ml-2 w-4 h-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.75} d='M19 9l-7 7-7-7' />
                        </svg>
                      </button>
                    </Select>
                    <ul className='bg-gray-900 border-2 border-gray-800 rounded-xl overflow-hidden'>
                      {infoCategoryList.map(category =>
                          <li key={'category-' + category.value}>
                            <button
                              className='truncate w-full text-left focus:outline-none hover:text-primary py-2 px-4'
                              onClick={(e) => onChange(category, 'category')}>
                              {category.label}
                            </button>
                          </li>
                        )
                      }
                    </ul>
                  </ToolTipController>
                </div>
                <div className='mb-2 mr-2 inline-block z-10'>
                  <ToolTipController
                    detect='click'
                    offsetY={10}
                    offsetX='right'
                    animation='fadeIn'
                    duration='.3s'
                    timing='ease'
                    properties={['opacity', 'transform']}>
                    <Select>
                      <button className={'btn-gray flex items-center border-2' + (data.status.value === 'published' ? ' border-primary' : ' border-gray-900')}>
                        <span className='text-gray-700 mr-2 truncate'>Status</span>
                        {data.status.label}
                        <svg className='block ml-2 w-4 h-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.75} d='M19 9l-7 7-7-7' />
                        </svg>
                      </button>
                    </Select>
                    <ul className='bg-gray-900 border-2 border-gray-800 rounded-xl overflow-hidden'>
                      {infoStatusList.map(status =>
                        <li key={'status-' + status.value}>
                          <button
                            className='w-full text-left focus:outline-none hover:text-primary py-2 px-4'
                            onClick={(e) => onChange(status, 'status')}>
                            {status.label}
                          </button>
                        </li>
                      )}
                    </ul>
                  </ToolTipController>
                </div>
              </div>
            </div>

            <div className='w-full max-w-2xl m-auto'>
              <div className='bg-gray-900 mb-6 rounded-xl'>
                <div className='flex items-center'>
                  <label htmlFor='title'>
                    <h3 className='p-4 w-32 text-gray-600'>Title</h3>
                  </label>
                  <input
                    type='text'
                    className='input border-l flex-1 p-4 focus:outline-none w-full'
                    id='title'
                    defaultValue={data.title}
                    placeholder='Untitled'
                    onChange={(e) => onChange(e.target.value, 'title')} />
                </div>
                <div className='flex items-center border-t-2 border-gray-800 relative'>
                  <label htmlFor='slug'>
                    <h3 className='p-4 w-32 text-gray-600'>Link</h3>
                  </label>
                  <input
                    type='text'
                    className='input text-gray-600'
                    id='link'
                    value={'https://risingsun.solar/info/' + data.slug}
                    disabled />
                    <button
                      className='p-4 focus:outline-none outline-none hover:text-brand transition-all duration-200'
                      onClick={() => {
                        function listener(e) {
                          e.preventDefault()
                          e.clipboardData.setData('text/plain', 'https://risingsun.solar/info/' + data.slug)
                        }
                        document.addEventListener('copy', listener)
                        document.execCommand('copy')
                        document.removeEventListener('copy', listener)
                      }}>
                      <svg className='h-5 w-5' stroke='currentColor' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1} d='M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3' />
                      </svg>
                    </button>
                </div>
                <div className='flex items-center border-t-2 border-gray-800 relative'>
                  <label htmlFor='slug'>
                    <h3 className='p-4 w-32 text-gray-600'>Slug</h3>
                  </label>
                  <input
                    type='text'
                    className='input text-gray-600'
                    id='slug'
                    value={'/info/' + data.slug}
                    disabled />
                    <button
                      className='p-4 focus:outline-none outline-none hover:text-brand transition-all duration-200'
                      onClick={() => {
                        function listener(e) {
                          e.preventDefault()
                          e.clipboardData.setData('text/plain', '/info/' + data.slug)
                        }
                        document.addEventListener('copy', listener)
                        document.execCommand('copy')
                        document.removeEventListener('copy', listener)
                      }}>
                      <svg className='h-5 w-5' stroke='currentColor' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1} d='M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3' />
                      </svg>
                    </button>
                </div>
              </div>

              <div className='bg-gray-900 rounded-xl mb-6'>
                <div className='flex border-b-2 border-gray-800'>
                  <label htmlFor='metaDescription'>
                    <h3 className='p-4 w-32 leading-none text-gray-600'>Meta Description</h3>
                  </label>
                  <textarea
                    maxLength='160'
                    className='input leading-tight h-32 flex-1 p-4 focus:outline-none w-full'
                    id='metaDescription'
                    defaultValue={data.metaDescription}
                    placeholder='No more than 160 characters.'
                    onChange={(e) => onChange(e.target.value, 'metaDescription')} />
                </div>
                <div className='flex items-center'>
                  <label htmlFor='metaKeywords'>
                    <h3 className='p-4 w-32 leading-none text-gray-600'>Keywords</h3>
                  </label>
                  <input
                    type='text'
                    className='input flex-1 p-4 focus:outline-none w-full'
                    id='metaKeywords'
                    defaultValue={data.metaKeywords}
                    placeholder='Comma separated list.'
                    onChange={(e) => onChange(e.target.value, 'metaKeywords')} />
                </div>
              </div>
            </div>

            <div className='w-full max-w-2xl m-auto text-sm text-gray-700 mb-6'>
              <button className='flex items-center'>
                {data.authorPhoto &&
                  <img
                    className='mr-2 h-10 w-10 border-2 border-gray-800 rounded-full'
                    src={data.authorPhoto.url}
                    alt='Post author.' />
                }
                {!data.authorPhoto &&
                  <div className='flex items-center justify-center mr-2 bg-gray-100 border h-10 w-10 rounded-full'>
                    <svg className='h-5 w-5 text-gray-900' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
                      <path fillRule='evenodd' d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z' clipRule='evenodd' />
                    </svg>
                  </div>
                }
                By {data.authorName}
              </button>
            </div>

            <div className='w-full max-w-2xl m-auto mb-6'>
              <FeatureImage data={data} fileName='featureImage' onChange={onChange} />
            </div>

            <div className='bg-gray-900 rounded-xl overflow-hidden mb-6 w-full max-w-2xl m-auto'>
              <TinyEditor
                id={data.id}
                initialState={data.content}
                setPostContent={(e) => onChange(e, 'content')} />
            </div>

            <button
              className='btn-gray block m-auto mt-32'
              onClick={onRemove}>
              Delete Post
            </button>

          </div>
        }
      </main>
    </Layout>
  )
}
