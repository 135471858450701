import { signOut } from 'lib/firebase/use-auth'
import { matchPath } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

import { ToolTipController, Select } from 'react-tooltip-controller'

export default function Menu() {
  return (
    <ul className='flex'>
      <li className='mr-2'>
        <ToolTipController
          detect='click'
          offsetY={10}
          offsetX='right'
          animation='fadeIn'
          duration='.3s'
          timing='ease'
          properties={['opacity', 'transform']}>
          <Select>
            <button className='h-14 w-14 rounded-full bg-gray-900 hover:bg-gray-800 transition duration-150 flex focus:outline-none'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 m-auto' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.75} d='M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z' />
              </svg>
            </button>
          </Select>
          <ContentApps />
        </ToolTipController>
      </li>
      <li>
        <ToolTipController
          detect='click'
          offsetY={10}
          offsetX='right'
          animation='fadeIn'
          duration='.3s'
          timing='ease'
          properties={['opacity', 'transform']}>
          <Select>
            <button className='h-14 w-14 rounded-full bg-gray-900 hover:bg-gray-800 transition duration-150 flex focus:outline-none'>
              <svg className='h-6 w-6 m-auto' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.75} d='M4 8h16M4 16h16' />
              </svg>
            </button>
          </Select>
          <ContentOptions />
        </ToolTipController>
      </li>
    </ul>
  )
}

function ContentApps() {
  return (
    <div className='bg-gray-900 border border-gray-800 rounded-xl'>
      <ul className='p-6 grid grid-cols-4 gap-2'>
        {apps.map(node =>
          <li key={node.label}>
            <a
              className={'group border-2 border-current block h-16 w-16 duration-150 transform hover:scale-105 flex rounded-xl bg-gray-900 ' + node.color}
              href={node.href}>
              <span className='flex items-center justify-center absolute top-0 left-0 h-full w-full text-2xl block transform scale-100 opacity-100 duration-150 group-hover:scale-0 group-hover:opacity-0'>{node.icon}</span>
              <span className='flex items-center justify-center absolute top-0 left-0 h-full w-full text-xs block transform scale-0 opacity-0 duration-150 group-hover:scale-100 group-hover:opacity-100'>{node.label}</span>
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

function ContentOptions() {
  const location = useLocation()
  function isChild(path) {
    return path === '/' ?
    matchPath(location.pathname, {
      path: path,
      exact: true,
      strict: true
    })
    :
    matchPath(location.pathname, {
      path: path,
      exact: false,
      strict: false
    })
  }
  return (
    <div className='bg-gray-900 border border-gray-800 rounded-xl w-72'>
      <ul className='p-6 divide-y-2 divide-gray-800'>
        {options.map(node => {
          const match = isChild(node.href)
          return (
            <li key={node.label}>
              <Link
                className={`group flex py-2`}
                to={node.href}>
                {match &&
                  <svg xmlns='http://www.w3.org/2000/svg' className='text-primary h-6 w-6 mr-2' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M13 7l5 5m0 0l-5 5m5-5H6' />
                  </svg>
                }
                <span className={`block transition-all duration-200 ${!match && 'group-hover:pl-2 group-hover:text-primary'}`}>{node.label}</span>
              </Link>
            </li>
          )
        })}
      </ul>
      <div className='m-6'>
        <button
          onClick={signOut}
          className='btn-gray-800-border w-full'>
          Sign Out
        </button>
      </div>
    </div>
  )
}

const apps = [
  {
    label: 'Ops',
    short: 'Op',
    href: 'https://ops.risingsun.solar',
    color: 'text-risingsun-600',
    icon:
      <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
        <path fillRule='evenodd' d='M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z' clipRule='evenodd' />
      </svg>
  },{
    label: 'Control',
    short: 'Co',
    href: 'https://control.risingsun.solar',
    color: 'text-royalblue-600',
    icon:
      <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z' />
      </svg>
  },{
    label: 'Comms',
    short: 'Cm',
    href: 'https://comms.risingsun.solar',
    color: 'text-limegreen-600',
    icon:
      <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z' />
      </svg>
  },{
    label: 'Plans',
    short: 'Pl',
    href: 'https://plans.risingsun.solar',
    color: 'text-flamingo-600',
    icon:
      <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2' />
      </svg>
  }
]

const options = [
  {
    label: 'Dashboard',
    short: 'Da',
    href: '/',
    color: 'text-primary'
  },{
    label: 'Blog Posts',
    short: 'Bl',
    href: '/blog',
    color: 'text-primary'
  },{
    label: 'Info Pages',
    short: 'Pa',
    href: '/info',
    color: 'text-primary'
  },{
    label: 'Locations',
    short: 'Lo',
    href: '/locations',
    color: 'text-primary'
  },{
    label: 'Team',
    short: 'Te',
    href: '/team',
    color: 'text-primary'
  }
]
