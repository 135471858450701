import Select from 'react-select'

export default function MySelect({ placeholder, icon, value, name, label, onChange, options, className, size }) {
  return (
    <div className={`relative ${className}`}>
      {icon &&
        <label htmlFor={name} className='pointer-events-none z-10 absolute left-0 h-full flex items-center cursor-pointer'>
          {icon}
        </label>
      }
      <Select
        className={`${size !== undefined ? 'my-react-select-container-' + size : 'my-react-select-container'} ${icon !== undefined && icon !== false && 'has-icon'}`}
        classNamePrefix='react-select'
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
      />
      <label className='pointer-events-none absolute top-0 right-8 h-full flex items-center cursor-pointer block px-3 leading-tight text-gray-600 text-xs' htmlFor={name}>{label}</label>
    </div>
  )
}
