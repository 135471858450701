import { useAuth } from 'lib/firebase/use-auth'
import { db } from 'lib/firebase/firebase'

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Layout from 'components/layout'

export default function Index() {
  useAuth()

  const [createdPosts, setCreatedPosts] = useState(false)
  const [updatedPosts, setUpdatedPosts] = useState(false)

  useEffect(() => {
    const unsubscribe = []
    setTimeout(() => {
      unsubscribe.push(
        db.collection('blog-posts')
          .orderBy('createdAt', 'asc')
          .limit(5)
          .onSnapshot(snapshot => {
            const posts = []
            snapshot.forEach(doc => posts.push(doc))
            setCreatedPosts(posts)
          })
      )
      unsubscribe.push(
        db.collection('blog-posts')
          .orderBy('updatedAt', 'desc')
          .limit(5)
          .onSnapshot(snapshot => {
            const posts = []
            snapshot.forEach(doc => posts.push(doc))
            setUpdatedPosts(posts)
          })
      )
    }, 990)
    return () => unsubscribe.forEach(u => u())
  }, [])

  return (
    <Layout>
      <main>
        <div className='py-32'>
          <div className='px-6 max-w-4xl m-auto'>
            <div className='bg-gray-900 rounded-xl mb-6 overflow-hidden'>
              <div className='relative fade-up'>
                <span className='block aspect-ratio-21/9' />
                <span className='absolute inset-0 flex h-full w-full'>
                  <img alt='Hero' src='./img/design/hero.png' />
                </span>
              </div>
            </div>
          </div>

          <div className='px-6 max-w-4xl m-auto'>
            <div className='bg-gray-900 rounded-xl p-4 mb-6'>
              <div className='grid sm:grid-cols-3 gap-4'>
                <Link
                  to={'/blog'}
                  className='relative border-2 border-gray-800 rounded-xl'>
                  <span className='block aspect-ratio-16/9' />
                  <span className='absolute inset-0 flex h-full w-full'>
                    <span className='m-auto'>Blog Posts</span>
                  </span>
                </Link>
                <Link
                  to={'/info'}
                  className='relative border-2 border-gray-800 rounded-xl'>
                  <span className='block aspect-ratio-16/9' />
                  <span className='absolute inset-0 flex h-full w-full'>
                    <span className='m-auto'>Info Pages</span>
                  </span>
                </Link>
                <Link
                  to={'/team'}
                  className='relative border-2 border-gray-800 rounded-xl'>
                  <span className='block aspect-ratio-16/9' />
                  <span className='absolute inset-0 flex h-full w-full'>
                    <span className='m-auto'>Team</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className='grid sm:grid-cols-2 gap-6 px-6 max-w-4xl m-auto'>
            <List title='Recently Created Posts' docs={createdPosts} />
            <List title='Recently Updated Posts' docs={updatedPosts} />
          </div>
        </div>
      </main>
    </Layout>
  )
}

function List({ title, docs }) {
  return (
    <div className='bg-gray-900 rounded-xl'>
      <div className='p-4 pb-12'>{title}</div>
      <ul className='divide-y-2 divide-gray-800 text-sm'>
        {docs && docs.map((doc) =>
          <li className='fade-in' key={'list-'+doc.id}>
            <Link
              to={'/blog/'+doc.id}
              className='group px-4 py-2 block w-full text-left flex items-center focus:outline-none'>
              <span className='pr-4'>
                <span className='block h-12 w-12 rounded-full overflow-hidden bg-gray-900'>
                  {doc.data().featureImage && <img className='h-full w-full object-cover' alt={title + ' featured image.'} src={doc.data().featureImage['100'] && doc.data().featureImage['100'].url} />}
                </span>
              </span>
              <span className='group-hover:text-primary transition duration-150 flex-1 truncate pr-4'>{doc.data().title ? doc.data().title : <span className='text-gray-600'>Untitled</span>}</span>
              <span className='flex-1 truncate text-gray-600 text-right'>{doc.data().category.label}</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}
