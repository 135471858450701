export const infoCategoryList = [
  {
    label: 'Landing Page',
    slug: 'landing-page'
  }
]

export function getCategoryLabelFromSlug(slug) {
  if(slug) {
    const category = infoCategoryList.filter(category => category.slug === slug)
    if(category) return category[0].label
  }
  return false
}

export function getCategorySlugFromLabel(label) {
  if(label) {
    const category = infoCategoryList.filter(category => category.label === label)
    if(category) return category[0].slug
  }
  return false
}

export const infoStatusList = [
  {
    label: 'Draft',
    value: 'draft'
  },{
    label: 'Published',
    value: 'published'
  }
]

export function getStatusLabelFromSlug(slug) {
  if(slug) {
    const status = infoStatusList.filter(status => status.slug === slug)
    if(status) return status[0].label
  }
  return false
}

export function getStatusSlugFromLabel(label) {
  if(label) {
    const status = infoCategoryList.filter(status => status.label === label)
    if(status) return status[0].slug
  }
  return false
}
