const createKeywords = (words) => {
  const keys = []
  let curr = ''
  // split unique words
  words.split(' ').forEach(word => {
    curr = ''
    word.split('').forEach(letter => {
      curr += letter
      keys.push(curr.toLowerCase())
    })
  })
  curr = ''
  // split whole phrase
  words.split('').forEach(letter => {
    curr += letter
    keys.push(curr.toLowerCase())
  })
  return keys
}

export const generateKeywords = (words) => {
  const keywords = []
  // loop and generate keys for all words in array
  words.forEach(word => {
    if(word && word.length) keywords.push(...createKeywords(word))
  })
  return [...new Set([...keywords])]
}
