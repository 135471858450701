import { useRef, useMemo } from 'react'
import useStorage from 'lib/useStorage'
import { Editor } from '@tinymce/tinymce-react'

import Resizer from 'react-image-file-resizer'

export default function TinyEditor(props) {
  const storage = useStorage()
  const editorRef = useRef(null)

  const initialState = useMemo(() => {
    return props.initialState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function resizeFile(file, maxWidth=1200, maxHeight=2400) {

    return new Promise((resolve) => {
      Resizer.imageFileResizer(file, maxWidth, maxHeight, 'JPEG', 80, 0, (uri) => { resolve(uri) }, 'file')
    })
  }

  async function images_upload_handler(blobInfo, success, failure, progress) {
    const file = blobInfo.blob()
    const time = new Date().getTime()

    if(file.type === 'image/gif') {
      storage.uploadImage(file, 'images/info-pages/'+props.id+'/content/'+time+'/'+time+'.gif')
      .then(image => {
        success(image.url)
      })
      .catch(error => {
        failure(error)
      })
    } else {
      console.log('not gif')
      const image = await resizeFile(file)
      storage.uploadImage(image, 'images/info-pages/'+props.id+'/content/'+time+'/'+time+'.jpg')
      .then(image => {
        success(image.url)
      })
      .catch(error => {
        failure(error)
      })
    }
  }

  function onChange(ed) {
    console.log('change')
    props.setPostContent(ed.getContent())
  }

  return (
    <Editor
      tinymceScriptSrc={'/script/tinymce/tinymce.min.js'}
      onInit={(evt, editor) => editorRef.current = editor}
      initialValue={initialState}
      init={{
        setup: function (ed) {
          ed.on('keyup', function (e) {
            onChange(ed)
          })
          ed.on('change', function(e) {
            onChange(ed)
          })
        },
        block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
        menubar: false,
        object_resizing : false,
        image_dimensions: false,
        image_title: true,
        toolbar_sticky: true,
        images_upload_handler: images_upload_handler,
        media_dimensions: false,
        plugins: [ 'autoresize advlist autolink lists link image media' ],
        toolbar: 'undo redo | formatselect | blockquote | ' +
        'bold italic underline | bullist numlist | link image media | ' +
        'removeformat',
        content_style: `
          @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
          body { font-family: 'Space Mono', monospace; background: none; color: white; }
          img { width: 100%; height: auto; border-radius: 12px; }
          h1 { line-height: 1.1; font-family: 'Rajdhani', sans-serif; text-transform: uppercase; letter-spacing: .02em; margin-bottom: 16px; }
          h2 { line-height: 1.1; font-family: 'Rajdhani', sans-serif; text-transform: uppercase; letter-spacing: .02em; margin-bottom: 16px; }
          h3 { line-height: 1.1; font-family: 'Rajdhani', sans-serif; text-transform: uppercase; letter-spacing: .02em; margin-bottom: 16px; }
          p { margin-bottom: 24px; }
          a { color: #0963ef; }
          strong, b { font-weight: bold; }
          blockquote, q { background: rgba(255,255,255,.1); display: block; padding: 20px!important; border: 0!important; outline: 0; margin: 0!important; quotes: none; border-radius: 14px;  margin-bottom: 16px; }
          blockquote:before, blockquote:after,
          q:before, q:after {
            content: '';
            content: none;
          }
          .mce-object-iframe {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;
            border-radius: 12px;
          }
          .mce-object-iframe iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        `
      }}
    />
  )
}
