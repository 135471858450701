import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import SignIn from 'pages/auth/sign-in'
import Dashboard from 'pages/dashboard/index'
import Blog from 'pages/blog/index'
import BlogId from 'pages/blog/id'
import InfoPages from 'pages/info/index'
import InfoId from 'pages/info/id'
import Locations from 'pages/locations/index'
import Team from 'pages/team/index'

export default function Index() {
  return (
    <Router>
      <Switch>
        <Route exact path='/signin'>
          <SignIn />
        </Route>
        <Route exact path='/'>
          <Dashboard />
        </Route>
        <Route exact path='/blog'>
          <Blog />
        </Route>
        <Route exact path='/blog/:id'>
          <BlogId />
        </Route>
        <Route exact path='/info'>
          <InfoPages />
        </Route>
        <Route exact path='/info/:id'>
          <InfoId />
        </Route>
        <Route exact path='/locations'>
          <Locations />
        </Route>
        <Route exact path='/team'>
          <Team />
        </Route>
      </Switch>
    </Router>
  )
}
