export default function Input({ icon, placeholder, value, name, label, onChange, type, className, size }) {
  return (
    <div className='relative'>
      {icon &&
        <label htmlFor={name} className='absolute left-0 h-full flex items-center cursor-pointer'>
          {icon}
        </label>
      }
      {type === 'textarea' ?
        <textarea
          value={value || ''}
          placeholder={placeholder}
          className={`h-32 input input-icon appearance-none ${icon !== undefined && icon !== false && 'has-icon'} ${className}`}
          id={name}
          type={type}
          onChange={onChange} />
        :
        <input
          value={value || ''}
          placeholder={placeholder}
          className={`${size !== undefined ? 'input-' + size : 'input'} input-icon appearance-none ${icon !== undefined && icon !== false && 'has-icon'} ${className}`}
          id={name}
          type={type}
          onChange={onChange} />
        }
      <label className='absolute right-0 top-0 h-full flex items-center cursor-pointer block px-3 leading-tight text-gray-600 text-xs' htmlFor={name}>{label}</label>
    </div>
  )
}
