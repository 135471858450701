import { useRef, useEffect, useState, useMemo } from 'react'
import useStorage from 'lib/useStorage'
import { Editor } from '@tinymce/tinymce-react'

import Resizer from 'react-image-file-resizer'

export default function TinyEditor(props) {
  const storage = useStorage()
  const editorRef = useRef(null)
  const [editorSet, setEditorSet] = useState(false)
  const [updateHub, setUpdateHub] = useState(false)

  const initialState = useMemo(() => {
    return props.initialState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(editorSet && editorRef.current) {
      const script = editorRef.current.contentDocument.createElement('script')
      script.src = 'https://js.hsforms.net/forms/v2.js'
      editorRef.current.contentDocument.body.appendChild(script)
      setUpdateHub(true)
    }
  }, [editorSet, editorRef])

  useEffect(() => {
    if(updateHub && editorRef.current) {
      setTimeout(() => {
        loadHubForms(editorRef.current)
      }, 400)
    }
    setUpdateHub(false)
  }, [updateHub, editorRef])

  const loadHubForms = function(editor) {
    if(editor.contentWindow.hbspt) {
      const forms = editor.contentDocument.getElementsByClassName('hubform-embed')
      const formsArray = Array.from(forms)
      formsArray.forEach((form, index) => {
        // if(form.dataset.created !== 'true') {
          form.innerHTML = `<div>Loading...</div>`
          const formId = form.dataset.id
          const formTarget = 'hubform-embed-' + formId + '-' + index
          form.id = formTarget
          editor.contentWindow.hbspt.forms.create({
            region: 'na1',
            portalId: '3446639',
            formId: formId,
            target: '#' + formTarget,
            onBeforeFormInit: () => {
              form.innerHTML = ''
            }
          })
        // }
      })
    }
  }

  function resizeFile(file, maxWidth=1200, maxHeight=2400) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(file, maxWidth, maxHeight, 'JPEG', 80, 0, (uri) => { resolve(uri) }, 'file')
    })
  }

  async function images_upload_handler(blobInfo, success, failure, progress) {
    const file = blobInfo.blob()
    const time = new Date().getTime()

    if(file.type === 'image/gif') {
      storage.uploadImage(file, 'images/blog-posts/'+props.id+'/content/'+time+'/'+time+'.gif')
      .then(image => {
        success(image.url)
      })
      .catch(error => {
        failure(error)
      })
    } else {
      console.log('not gif')
      const image = await resizeFile(file)
      storage.uploadImage(image, 'images/blog-posts/'+props.id+'/content/'+time+'/'+time+'.jpg')
      .then(image => {
        success(image.url)
      })
      .catch(error => {
        failure(error)
      })
    }
  }

  function onChange(ed) {
    console.log('change')
    props.setPostContent(ed.getContent())
  }

  return (
    <Editor
      tinymceScriptSrc={'/script/tinymce/tinymce.min.js'}
      onInit={(evt, editor) => {
        editorRef.current = editor
        setEditorSet(true)
      }}
      initialValue={initialState}
      init={{
        setup: function (editor) {
          editor.on('keyup', function (e) {
            onChange(editor)
          })
          editor.on('change', function(e) {
            onChange(editor)
          })
          // Add a button that opens a window
          editor.ui.registry.addButton('hubFormButton', {
            icon: 'insert-form',
            context: 'tools',
            onAction: function() {
              editor.windowManager.open({
                title: 'Embed a Hubspot Form',
                body: {
                  type: 'panel',
                  items: [ {
                    type: 'input',
                    name: 'id',
                    inputMode: 'text',
                    label: 'Hubspot Form ID'
                  } ],
                },
                buttons: [ // A list of footer buttons
                  {
                    type: 'submit',
                    text: 'Submit'
                  }
                ],
                onSubmit: function(e) {
                  editor.insertContent(`<div data-id='${e.getData().id}' class='hubform-embed' />`)
                  setUpdateHub(true)
                  e.close()
                }
              })
            }
          })
        },
        block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
        menubar: false,
        object_resizing : false,
        image_dimensions: false,
        image_title: true,
        toolbar_sticky: true,
        images_upload_handler: images_upload_handler,
        media_dimensions: false,
        plugins: [ 'autoresize advlist autolink lists link image media' ],
        toolbar: 'undo redo | formatselect | link ' +
        'bold italic underline blockquote | bullist numlist | image media hubFormButton | ' +
        'removeformat',
        content_style: `
          @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
          body { font-family: 'Space Mono', monospace; background: none; color: white; }
          img { width: 100%; height: auto; border-radius: 12px; }
          h1 { line-height: 1.1; font-family: 'Rajdhani', sans-serif; text-transform: uppercase; letter-spacing: .02em; margin-bottom: 16px; }
          h2 { line-height: 1.1; font-family: 'Rajdhani', sans-serif; text-transform: uppercase; letter-spacing: .02em; margin-bottom: 16px; }
          h3 { line-height: 1.1; font-family: 'Rajdhani', sans-serif; text-transform: uppercase; letter-spacing: .02em; margin-bottom: 16px; }
          p { margin-bottom: 24px; }
          a { color: #0963ef; }
          strong, b { font-weight: bold; }
          blockquote, q { background: rgba(255,255,255,.1); display: block; padding: 20px!important; border: 0!important; outline: 0; margin: 0!important; quotes: none; border-radius: 14px;  margin-bottom: 16px; }
          blockquote:before, blockquote:after,
          q:before, q:after {
            content: '';
            content: none;
          }
          .mce-object-iframe {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;
            border-radius: 12px;
          }
          .mce-object-iframe iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        `
      }}
    />
  )
}
