import slugify from 'slugify'
import { format } from 'date-fns'

export const blogCategoryList = [
  {
    label: 'Solar News',
    slug: 'solar-news'
  },{
    label: 'Why Go Solar',
    slug: 'why-go-solar'
  },{
    label: 'Solar 101',
    slug: 'solar-101'
  },{
    label: 'Solar Financing',
    slug: 'solar-financing'
  },{
    label: 'Cost-Benefit',
    slug: 'cost-benefit'
  },{
    label: 'Decision Guide',
    slug: 'decision-guide'
  },{
    label: 'Solar Batteries',
    slug: 'solar-batteries'
  },{
    label: 'Ask An Expert',
    slug: 'ask-an-expert'
  },{
    label: 'Solar Operations & Maintenance',
    slug: 'solar-operations-and-maintenance'
  },
]

export function getCategoryLabelFromSlug(slug) {
  if(slug) {
    const category = blogCategoryList.filter(category => category.slug === slug)
    if(category) return category[0].label
  }
  return false
}

export function getCategorySlugFromLabel(label) {
  if(label) {
    const category = blogCategoryList.filter(category => category.label === label)
    if(category) return category[0].slug
  }
  return false
}

export const blogStatusList = [
  {
    label: 'Draft',
    slug: 'draft'
  },{
    label: 'Published',
    slug: 'published'
  }
]

export function getStatusLabelFromSlug(slug) {
  if(slug) {
    const status = blogStatusList.filter(status => status.slug === slug)
    if(status) return status[0].label
  }
  return false
}

export function getStatusSlugFromLabel(label) {
  if(label) {
    const status = blogCategoryList.filter(status => status.label === label)
    if(status) return status[0].slug
  }
  return false
}

export const getSlug = (string) => {
  return slugify(string, {remove: /[*+~":.,/;?()''!@]/g}).toLowerCase()
}

export const getPostDate = (date) => {
  return format(new Date(date), 'yyyy-MM-dd')
}
