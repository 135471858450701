import { useAuth } from 'lib/firebase/use-auth'
import { db } from 'lib/firebase/firebase'
import useLocalStorage from 'lib/useLocalStorage'

import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Modal from 'react-modal'
import Layout from 'components/layout'

import Input from 'components/input'
import MySelect from 'components/select'

import { useDebouncedCallback } from 'use-debounce'

import { getSlug, getPostDate } from 'lib/post'
import { blogCategoryList, blogStatusList } from 'lib/blog'

const categories = [
  {
    value: 'education',
    label: 'Education'
  },{
    value: 'news',
    label: 'News'
  }
]
let unsubscribe = () => {}

export default function Index() {

  const auth = useAuth()
  const history = useHistory()

  const [filter, setFilter] = useLocalStorage(process.env.REACT_APP_LOCAL_STORAGE + 'filters-posts', categories[0])
  const [view, setView] = useLocalStorage(process.env.REACT_APP_LOCAL_STORAGE + 'views-posts', 'list')
  const [search, setSearch] = useState(false)

  const [posts, setPosts] = useState([])

  useEffect(() => {
    Modal.setAppElement('body')
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    if(search) {
      debouncedFetchData()
    } else if(search !== false) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const debouncedFetchData = useDebouncedCallback(() => {
    fetchData()
  }, 1000)

  function fetchData() {
    console.log('fetch')
    unsubscribe()
    if(search && search !== '') {
      if(filter) {
        // with search & filter
        unsubscribe = db.collection('blog-posts')
        .where('category.value', '==', filter.value)
        .where('keywords', 'array-contains', search.toLowerCase())
        .orderBy('createdAt', 'desc')
        .limit(21)
        .onSnapshot(snapshot => {
          const docs = []
          snapshot.forEach(doc => docs.push(doc))
          setPosts(docs)
        })
      } else {
        // with search & no filter
        unsubscribe = db.collection('blog-posts')
        .where('keywords', 'array-contains', search.toLowerCase())
        .orderBy('createdAt', 'desc')
        .limit(21)
        .onSnapshot(snapshot => {
          const docs = []
          snapshot.forEach(doc => docs.push(doc))
          setPosts(docs)
        })
      }
    } else {
      if(filter) {
        // with filter & no search
        unsubscribe = db.collection('blog-posts')
        .where('category.value', '==', filter.value)
        .orderBy('createdAt', 'desc')
        .limit(21)
        .onSnapshot(snapshot => {
          const docs = []
          snapshot.forEach(doc => docs.push(doc))
          setPosts(docs)
        })
      } else {
        // with no filter & no search
        unsubscribe = db.collection('blog-posts')
        .orderBy('createdAt', 'desc')
        .limit(21)
        .onSnapshot(snapshot => {
          const docs = []
          snapshot.forEach(doc => docs.push(doc))
          setPosts(docs)
        })
      }
    }
  }

  function createPost() {
    const date = new Date()
    db.collection('blog-posts').add({
      createdAt: date,
      updatedAt: date,
      status: blogStatusList[0],
      category: blogCategoryList[0],
      authorName: auth.name,
      authorPhoto: auth.photo
    })
    .then(doc => {
      history.push('/blog/' + doc.id)
    })
  }

  return (
    <Layout>
      <main>
        <ul className='fixed z-10 bottom-0 right-0 p-6'>
          <button className='btn-circle-primary-lg' onClick={createPost}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
            </svg>
          </button>
        </ul>
        {view === 'list' && <List posts={posts} filter={filter} setFilter={setFilter} setView={setView} search={search} setSearch={setSearch} />}
        {view === 'grid' && <Grid posts={posts} filter={filter} setFilter={setFilter} setView={setView} search={search} setSearch={setSearch} />}
      </main>
    </Layout>
  )
}

function Filter({ filter, setFilter, setView, search, setSearch }) {
  return (
    <div className='mb-6 flex w-full justify-between'>
      <div className='flex'>
        <ul className='grid grid-cols-2 gap-2'>
          <li>
            <Input
              type='text'
              icon=
              <svg className='h-5 w-5 mx-3 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' />
              </svg>
              size='sm'
              value={search}
              name='search'
              placeholder='Search'
              onChange={(e) => setSearch(e.target.value)}
            />
          </li>
          <li>
            <MySelect
              size='sm'
              value={filter}
              onChange={(e) => setFilter(e)}
              options={categories}
              placeholder='Filter'
            />
          </li>
        </ul>
        {filter &&
          <button className='px-2 text-gray-600 hover:text-primary transition duration-150 focus:outline-none' onClick={() => setFilter(null)}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
            </svg>
          </button>
        }
      </div>
      <div className='flex'>
        <button className='btn-circle-gray mr-2' onClick={() => setView('list')}>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M4 6h16M4 10h16M4 14h16M4 18h16' />
          </svg>
        </button>
        <button className='btn-circle-gray' onClick={() => setView('grid')}>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z' />
          </svg>
        </button>
      </div>
    </div>
  )
}

function List({ posts, filter, setFilter, setView, search, setSearch }) {
  return (
    <div className='p-6 py-32 w-full max-w-4xl m-auto'>
      <Filter filter={filter} setFilter={setFilter} setView={setView} search={search} setSearch={setSearch} />
      <ul className='divide-y-2 divide-gray-900'>
        {posts.map((doc) => {
          const title = doc.data().title ? doc.data().title : 'Untitled'
          return (
            <li className='fade-in' key={'list-'+doc.id}>
              <Link
                to={'/blog/' + doc.id}
                className='group py-2 block w-full text-left flex items-center focus:outline-none'>
                <span className='pr-4'>
                  <span className='block h-12 w-12 rounded-full overflow-hidden bg-gray-900'>
                    {doc.data().featureImage && <img className='h-full w-full object-cover' alt={title + ' featured image.'} src={doc.data().featureImage['100'] && doc.data().featureImage['100'].url} />}
                  </span>
                </span>
                <span className={`group-hover:text-primary transition duration-150 flex-1 truncate pr-4 ${title === 'Untitled' && 'text-gray-600'}`}>{title}</span>
                <span className='flex-1 truncate text-gray-600 text-right'>{doc.data().category.label}</span>
              </Link>
            </li>
          )}
        )}
      </ul>
    </div>
  )
}

function Grid({ posts, filter, setFilter, setView, search, setSearch }) {
  return (
    <div className='p-6 py-32 w-full max-w-4xl m-auto'>
      <Filter filter={filter} setFilter={setFilter} setView={setView} search={search} setSearch={setSearch} />
      <ul className='grid grid-cols-3 gap-6'>
        {posts.map((doc) => {
          const title = doc.data().title ? doc.data().title : 'Untitled'
          return (
            <li className='fade-in' key={'grid-'+doc.id}>
              <Link
              to={'/blog/' + doc.id}
                className='leading-tight border-2 border-gray-900 hover:border-primary transition duration-150 rounded-xl overflow-hidden block w-full text-left focus:outline-none'>
                <span className='block bg-gray-900 relative'>
                  <span className='block aspect-ratio-16/9' />
                  {doc.data().featureImage && <img className='absolute top-0 left-0 h-full w-full object-cover' alt={title + ' featured image.'} src={doc.data().featureImage['400'] && doc.data().featureImage['400'].url} />}
                </span>
                <span className={`block px-4 pt-2 truncate ${title === 'Untitled' && 'text-gray-600'}`}>{title}</span>
                <span className='block px-4 pb-2 truncate text-gray-600'>{doc.data().category.label}</span>
              </Link>
            </li>
          )}
        )}
      </ul>
    </div>
  )
}
